import type React from 'react';
import type { TFunction } from '@mentimeter/i18n';
import type { UserPoliciesT } from '@mentimeter/user';
import type {
  LocalPricingT,
  UpdateOptionsResponseT,
} from '@mentimeter/billing';
import type { FeatureT, PlansPageConfigT } from './types';
import { Bold } from './typography';
import { getPlansPreviewConfig } from './plansPreviewConfig';
import { getPlansFaqConfig } from './plansFaqConfig';
import EngagementProgressIllustration from './illustrations/engagement_progress_illustration.png';

export interface PlansPageConfigPropsT {
  isEducation: boolean;
  isFreeUser: boolean;
  loggedIn: boolean;
  userPolicies: Pick<
    UserPoliciesT,
    | 'isTrialUser'
    | 'isGenericProUser'
    | 'isGenericBasicUser'
    | 'isGenericBasicYearlyUser'
    | 'isGenericBasicMonthlyUser'
    | 'isEnterpriseUser'
  >;
  prices: LocalPricingT;
  isPriceLoading: boolean;
  monthlyToggled: boolean;
  possibleUpdates: UpdateOptionsResponseT | undefined;
  isInMonthlyTreatment: boolean;
  userEngagementLimitProgress: number | undefined;
  isInStairCaseTreatment: boolean;
  toggleSwitch: () => void;
  host: string;
  Link: React.ComponentType<{ href?: string; children?: React.ReactNode }>;
  trackPlan: (arg0: string) => void;
  t: TFunction;
  country: string | null;
  isInPlanNameExperiment?: boolean;
}

export const getPlansPageConfig = (
  props: PlansPageConfigPropsT,
): PlansPageConfigT => {
  const {
    isEducation,
    isFreeUser,
    loggedIn,
    userPolicies,
    prices,
    isPriceLoading,
    monthlyToggled,
    possibleUpdates,
    isInMonthlyTreatment,
    toggleSwitch,
    userEngagementLimitProgress,
    isInStairCaseTreatment,
    host,
    Link,
    trackPlan,
    t,
    country,
    isInPlanNameExperiment = false,
  } = props;
  const {
    isGenericBasicUser,
    isGenericProUser,
    isTrialUser,
    isEnterpriseUser,
  } = userPolicies;

  const plansPreviewConfig = getPlansPreviewConfig({
    isInPlanNameExperiment,
    isEducation,
    isFreeUser,
    userPolicies,
    prices,
    loggedIn,
    isPriceLoading,
    monthlyToggled,
    possibleUpdates,
    highlightedPlan: monthlyToggled ? 'basic' : 'pro',
    showRecommendedBadge: true,
    freePlanAction: {
      href: loggedIn ? '/app' : '/auth/signup?referral=plans',
      children: isFreeUser ? t('plans.current_plan') : t('plans.get_started'),
      disabled:
        isFreeUser ||
        isGenericProUser ||
        isGenericBasicUser ||
        isTrialUser ||
        isEnterpriseUser,
    },
    isInMonthlyTreatment,
    isOnboarding: false,
    userEngagementLimitProgress,
    isInStairCaseTreatment,
    host,
    Link,
    trackPlan,
    t,
    country,
  });

  return {
    toggleFunction: () => toggleSwitch(),
    monthlyToggled,
    isInMonthlyTreatment,
    title: isEducation
      ? [
          t('plans.education.boost_engagement_in_the_classroom.0'),
          t('plans.education.boost_engagement_in_the_classroom.1'),
        ]
      : [t('plans.heading_give_voice.0'), t('plans.heading_give_voice.1')],
    pageTitle: isEducation
      ? t('plans.education.educational_pricing')
      : t('plans.page_title'),
    description: isEducation
      ? t('plans.education.page_description')
      : t('plans.page_description'),
    isEducation,
    plans: plansPreviewConfig.plans,
    plansFeatures: [
      {
        id: 'active-presentations',
        section: t('plans.presentations'),
        features: [
          {
            heading: t('plans.participants_per_month'),
            description: t('plans.participants_per_month_description_v2'),

            illustrationUrl: EngagementProgressIllustration.src,

            free: '50',
            basic: t('plans.unlimited'),
            pro: t('plans.unlimited'),
            enterprise: t('plans.unlimited'),
          },
          {
            heading: t('plans.slide_types'),
            description: t('plans.slide_types_description'),
            free: '34',
            basic: t('plans.unlimited'),
            pro: t('plans.unlimited'),
            enterprise: t('plans.unlimited'),
          },
          {
            heading: t('plans.q&a'),
            description: t('plans.crowdsource_questions_from_audience'),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.usps.import_presentations'),
            description: t('plans.import_questions_from_pp_keynote_google'),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.make_presentations_private'),
            description: t('plans.make_presentations_private_description'),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
        ].filter(Boolean) as FeatureT[],
      },
      {
        id: 'branding-customization',
        section: t('plans.branding_and_customization'),
        features: [
          {
            heading: t('plans.quick_layouts'),
            description: t('plans.quick_layers_heading'),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.custom_colors'),
            description: t('plans.custom_colors_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.custom_themes'),
            description: t('plans.custom_themes_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.usps.company_branding'),
            description: t('plans.company_branding_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.add_logotype'),
            description: '',
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        id: 'teams',
        section: t('plans.teams'),
        features: [
          {
            heading: t('plans.teams_collaborative_workspace'),
            description: t('plans.teams_collaborative_workspace_description'),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.teams_workspace_roles'),
            description: t('plans.teams_workspace_roles_description'),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.teams_maximum_number_of_member_lite'),
            description: t(
              'plans.teams_maximum_number_of_member_lite_description',
            ),
            free: 'N/A',
            basic: '20',
            pro: '20',
            enterprise: t('plans.custom'),
          },
          {
            heading: t('plans.collaborate_on_presentations'),
            description: t('plans.collaborate_on_presentations_description'),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.invite_non_members'),
            description: t('plans.invite_non_members_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.teams_header'),
            description: t('plans.teams_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.teams_create_groups'),
            description: t('plans.teams_create_groups_description'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plans.teams_workspace_insights'),
            description: t('plans.teams_workspace_insights_description'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
        ],
      },
      {
        id: 'export',
        section: t('plans.export'),
        features: [
          {
            heading: t('plans.export_heading'),
            description: '',
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.export_excel'),
            description: '',
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        id: 'advanced-input',
        section: t('plans.advanced_input'),
        features: [
          {
            heading: t('plans.moderate_q&a'),
            description: t('plans.moderate_q&a_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.add_quick_forms'),
            description: t('plans.add_quick_forms_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.several_answers_from_one_device'),
            description: t('plans.several_answers_from_one_device_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        id: 'privacy-control',
        section: t('plans.privacy_and_control'),
        features: [
          {
            heading: t('plans.access_level'),
            description: t('plans.assign_admins'),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.join_settings'),
            description: t('plans.customize_who_can_join_organisation'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.sso'),
            description: t('plans.saml'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plans.scim'),
            description: t('plans.scim_description'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plans.custom_data_retention'),
            description: t('plans.custom_data_retention_description'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
        ],
      },
      {
        id: 'payment',
        section: t('plans.payment'),
        features: [
          {
            heading: t('plans.payment_bank_transfer'),
            description: t('plans.payment_bank_transfer_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        id: 'support',
        section: t('plans.support'),
        features: [
          {
            heading: t('plans.online_support'),
            description: '',
            free: t('plans.help_center'),
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plans.priority_support'),
            description: t('plans.priority_support_description'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plans.custom_onboarding'),
            description: t('plans.custom_onboarding_description'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
        ],
      },
    ],
    faq: getPlansFaqConfig({
      t,
      Link,
      Bold,
      isInMonthlyTreatment,
      isEducation,
    }),
    conferenceBanner: {
      heading: t('plans.conference_banner.heading'),
      description: t('plans.conference_banner.description'),
      actionText: t('plans.conference_banner.action_text'),
    },
  };
};
