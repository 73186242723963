/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import type { TextT } from '@mentimeter/ragnar-ui/text';
import { Text } from '@mentimeter/ragnar-ui/text';

export const Bold = (props: TextT) => (
  <Text
    as="span"
    fontWeight="semiBold"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);

export const Italic = (props: TextT) => (
  <Text
    as="span"
    fontStyle="italic"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);
